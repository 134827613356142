<template>
	<div class="food-types-slider-wr">
		<div class="food-types-slider">
			<carousel
				:perPageCustom="[[320, 1],[768, 2], [1279, 3]]"
				:navigationEnabled="true"
				:paginationEnabled="false"
				:navigationNextLabel="sliderBtns.next"
				:navigationPrevLabel="sliderBtns.prev"
			>
				<slide
					v-for="(item, index) in slides"
					:key="index"
					class="food-types-slider-item"
				>
					<img
						@click="linkTo(item.link)"
						:src="item.preview_picture"
						alt=""
						class="food-types-slider-item__img"
					>
					<span
						@click="linkTo(item.link)"
						class="button food-types-slider__btn"
					>{{ item.name }}</span>
				</slide>
			</carousel>
		</div>
	</div>
</template>
<script>
import qs from 'qs';
import animations from '@/mixins/animations.js'
import {Carousel, Slide} from 'vue-carousel';

export default {
	components: {Carousel, Slide},
	mixins: [animations],
	/**
	 * Массив слайдеров и настройки карусели
	 */
	data()
	{
		return {
			width: null,
			slides: [],
			sliderBtns:
				{
					prev: '<svg width="12" height="18"><use xlink:href="#prev-arrow-icon"></use></svg>',
					next: '<svg width="12" height="18"><use xlink:href="#next-arrow-icon"></use></svg>'
				}
		}
	},
	methods:
		{
			/**
			 * link to func
			 */
			linkTo(urlLink)
			{
				if (this.$store.state.address.restarauntId === false)
					return this.shakeAnimation();
				this.$router.push(urlLink);
			}
		},
	/**
	 * Достаем баннеры и кладем их в стейт
	 */
	async mounted()
	{
		let result = await this.$axios.post('/banners/getIndexBanners/');
		if (!result.data.success)
			return false;

		this.slides = result.data.banners;
	}
}
</script>
<style lang="scss">
.food-types-slider
{
	max-width: 900px;

	.VueCarousel-wrapper
	{
		padding: 0 0 25px;
	}

	.VueCarousel-navigation-button[data-v-453ad8cd]
	{
		background-color: #fff;
		border-radius: 60px;
		line-height: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
		outline: none !important;
	}

	.VueCarousel-navigation-button svg
	{
		display: block;
		margin: 0 auto;
	}

	.VueCarousel-navigation-prev[data-v-453ad8cd],
	.VueCarousel-navigation-next[data-v-453ad8cd]
	{
		top: -120px;
	}

	.VueCarousel-navigation-prev[data-v-453ad8cd]
	{
		position: absolute;
		padding-right: 11px !important;
		left: 60px;
	}

	.VueCarousel-navigation-next[data-v-453ad8cd]
	{
		padding-left: 11px !important;
		right: 77px;
	}

	.VueCarousel-navigation--disabled[data-v-453ad8cd]
	{
		opacity: unset;
		opacity: .5;
	}

	.VueCarousel-navigation-button[data-v-453ad8cd]:focus
	{
		outline: none;
	}

	.VueCarousel-navigation
	{
		position: absolute;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 1%;
	}
}

.food-types-slider-items
{
	display: flex;
}

.food-types-slider-item
{
	position: relative;
	padding-right: 17px;

	&:before
	{
		content: '';
		display: block;
		width: calc(100% - 47px);
		height: calc(100% - 10px);
		border-radius: 10px;
		box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.2);
		top: 0px;
		left: 15px;
		position: absolute;
		z-index: -1;
	}
}

.food-types-slider-item__img
{
	border-radius: 10px;
}

.food-types-slider__btn
{
	position: absolute;
	font-family: $mainFontBold;
	font-style: normal;
	font-size: 14px;
	line-height: 100%;
	padding-top: 6px;
	padding-bottom: 6px;
	min-width: 94px;
	left: 20px;
	bottom: 20px;
}

@media (max-width: 1279px)
{
	.food-types-slider-item:before
	{
		width: calc(100% - 190px);
	}
}

@media (max-width: 850px)
{
	.food-types-slider-item:before
	{
		width: calc(100% - 143px);
	}
}

@media (max-width: 767px)
{
	.food-types-slider-wr
	{
		overflow: hidden;
	}
	.food-types-slider
	{
		padding: 0 0 50px;

		.food-types-slider-item
		{
			max-width: 250px;
			max-height: 190px;
			margin-right: 30px;

			img
			{
				object-fit: cover;
				width: 250px;
				height: 190px;
			}
		}

		.VueCarousel-wrapper
		{
			width: 280px;
			margin: 0 auto;
			overflow: visible;
		}

		.VueCarousel-navigation-button[data-v-453ad8cd]
		{
			width: 35px;
			height: 35px;

			svg
			{
				width: 9px;
			}
		}
	}
}

@media (max-width: 425px)
{
	.food-types-slider-wr
	{
		overflow: hidden;
	}
	.food-types-slider
	{
		padding: 0 0 50px;

		.food-types-slider-item
		{
			max-width: 204px;
			max-height: 180px;
			margin-right: 15px;

			img
			{
				object-fit: cover;
				width: 204px;
				height: 180px;
			}
		}

		.VueCarousel-wrapper
		{
			width: 219px;
			margin: 0 auto;
			overflow: visible;
		}

		.VueCarousel-navigation-button[data-v-453ad8cd]
		{
			width: 35px;
			height: 35px;

			svg
			{
				width: 9px;
			}
		}
	}
}
</style>